import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './ServiceAreaMap.css'; // Import the CSS file

const ServiceAreaMap = () => {
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    fetch('/data/ohioZipCodes.geojson')
      .then((response) => response.json())
      .then((data) => setGeoData(data))
      .catch((error) => console.error('Error loading GeoJSON data:', error));
  }, []);

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.city) {
      layer.bindPopup(`<p>${feature.properties.city}</p>`);
    }
  };

  const geoJsonStyle = (feature) => {
    return {
      color: 'blue',
      weight: 2,
      opacity: 0.6,
      fillOpacity: 0.2,
      fillColor: 'blue'
    };
  };

  return (
    <div className="map-container"> {/* Use the map-container class for responsive design */}
      <MapContainer center={[39.9612, -82.9988]} zoom={10} className="map-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {geoData && <GeoJSON data={geoData} onEachFeature={onEachFeature} style={geoJsonStyle} />}
      </MapContainer>
    </div>
  );
};

export default ServiceAreaMap;
