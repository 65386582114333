import React from 'react';
import { Link } from 'react-router-dom';

const ContactBtn = () => {
  return (
    <div className="fixed top-4 left-4 z-50">
      <Link to="/contact">
        <button className="px-8 py-4 bg-red-600 text-white font-semibold text-lg rounded-lg hover:bg-orange-500 transition-all duration-300 ease-in-out shadow-lg">
          Contact Us
        </button>
      </Link>
    </div>
  );
};

export default ContactBtn;
