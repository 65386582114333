import React, { useEffect } from 'react';
import { motion } from 'framer-motion'; // Import framer-motion
import { useInView } from 'react-intersection-observer'; // Import useInView for scroll animations
import ContactBtn from '../Components/ContactBtn';
import ServiceAreaMap from '../Components/ServiceAreaMap';
import Footer from '../Components/Footer';

const About = () => {
  // Ensure page starts at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll-triggered animations for the sections
  const { ref: missionRef, inView: missionInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: historyRef, inView: historyInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: teamRef, inView: teamInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: valuesRef, inView: valuesInView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <div className="bg-black text-white min-h-screen">

      {/* Adjust z-index and positioning for the contact button */}
      <div className="z-50 fixed bottom-4 right-4"></div>
      <ContactBtn />

      {/* Hero Section */}
      <section className="hero bg-cover bg-center h-screen flex items-center justify-center" style={{ backgroundImage: "url('/assets/AboutPic.jpeg')" }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dark Overlay for background */}
        <div className="relative z-20 text-center px-4">
          <motion.h1 className="text-6xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}>
            About Us
          </motion.h1>
          <motion.p className="mt-4 text-2xl max-w-xl mx-auto font-bold text-white"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}>
            Learn more about our company, our mission, and the team that makes it all possible.
          </motion.p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 bg-gray-900">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
          <motion.div className="md:w-1/2 p-4"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, delay: 0.5 }}>
            <h2 className="text-4xl font-bold mb-6">Why Choose Murphy Fence LLC</h2>
            <p className="text-lg leading-relaxed">At Murphy Fence LLC, we pride ourselves...</p>
            <ul className="list-disc list-inside mt-4 text-lg">
              <li>High-Quality Materials</li>
              <li>Expert Installation</li>
              <li>Competitive Pricing</li>
              <li>Outstanding Customer Service</li>
            </ul>
          </motion.div>
          <div className="md:w-1/2 p-4 flex justify-center">
            <motion.img 
              src="/assets/WhyChoose.jpeg" 
              alt="Why Choose Us" 
              className="w-full h-auto max-w-lg rounded-lg shadow-lg object-cover"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5, delay: 0.5 }}
              loading="lazy" />
          </div>
        </div>
      </section>

      {/* Mission Section with Motion */}
      <section className="mb-12" ref={missionRef}>
        <motion.div
          className="container mx-auto px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={missionInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold mb-6">Our Mission</h2>
          <p className="text-lg leading-relaxed">
          At Murphy Fence LLC, our mission is to set the highest standards of quality in the fencing industry. 
          We strive to exceed customer expectations through premium craftsmanship, attention to detail, and a commitment to enduring value in every project we undertake. 
          By continuously elevating our methods and materials, we aim to be the benchmark for excellence, making us the preferred choice for those who demand nothing less than the best.
          </p>
        </motion.div>
      </section>

      {/* History Section with Motion */}
      <section className="mb-12" ref={historyRef}>
        <motion.div
          className="container mx-auto px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={historyInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold mb-6">Our History</h2>
          <p className="text-lg leading-relaxed">Murphy Fence LLC was founded with a commitment to bring the precision and quality craftsmanship of high-end home remodeling into the fencing industry. 
            Recognizing a significant gap in quality, our founders set out to elevate fencing standards by applying the meticulous attention to detail and excellence honed from years in luxury home renovation. 
            We are driven by the same principles of durability, design integrity, and customer satisfaction that defined their remodeling careers, ensuring each fence we create is built to the highest standards in the industry.</p>
        </motion.div>
      </section>

      {/* Team Section with Motion */}
      <section className="mb-12" ref={teamRef}>
        <motion.div
          className="container mx-auto px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={teamInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold mb-6">Meet the Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Team Member 1 */}
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={teamInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1.5, delay: 0.3 }}
            >
              <img 
                src="/assets/ConnorPic.jpeg" 
                alt="Team Member 1" 
                className="w-40 h-40 mx-auto rounded-full mb-4 object-cover shadow-lg"
                loading="lazy"
              />
              <h3 className="text-xl font-bold">Connor Murphy</h3>
              <p className="text-lg">Founder & CEO</p>
            </motion.div>

            {/* Team Member 2 */}
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={teamInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1.5, delay: 0.4 }}
            >
              <img 
                src="/assets/NathanPic.jpeg" 
                alt="Team Member 2" 
                className="w-40 h-40 mx-auto rounded-full mb-4 object-cover shadow-lg"
                loading="lazy"
              />
              <h3 className="text-xl font-bold">Nathan Holt</h3>
              <p className="text-lg">Operations Manager</p>
            </motion.div>

            {/* Team Member 3 */}
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={teamInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1.5, delay: 0.5 }}
            >
              <img 
                src="/assets/BrianPic.jpeg" 
                alt="Team Member 3" 
                className="w-40 h-40 mx-auto rounded-full mb-4 object-cover shadow-lg"
                loading="lazy"
              />
              <h3 className="text-xl font-bold">Brian Hohwald</h3>
              <p className="text-lg">Project Manager</p>
            </motion.div>
          </div>
        </motion.div>
      </section>

      {/* Values Section with Motion */}
      <section className="mb-12" ref={valuesRef}>
        <motion.div
          className="container mx-auto px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={valuesInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold mb-6">Our Values</h2>
          <ul className="list-disc list-inside text-lg leading-relaxed">
            <li>Quality Craftsmanship</li>
            <li>Customer Satisfaction</li>
            <li>Integrity and Honesty</li>
            <li>Continuous Improvement</li>
            <li>Community Involvement</li>
          </ul>
        </motion.div>
      </section>

      {/* Service Area Map Section */}
      <section className="py-16 bg-black relative z-10">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-6 text-center">Our Service Areas</h2>
          <div className="w-full h-96">
            <ServiceAreaMap />
          </div>
          <p className="mt-6 text-center text-xl font-semibold text-white">
            We service Delaware, Franklin, and Licking Counties in Ohio
          </p>
        </div>
        <div className="mt-12"></div> {/* Add some space below the map */}
      </section>

      <Footer />
    </div>
  );
};

export default About;