import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false); // Toggle for main hamburger menu
  const [isServicesOpen, setIsServicesOpen] = useState(false); // Toggle for services dropdown
  const navRef = useRef(); // Reference to the navbar

  // Handle click outside to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsServicesOpen(false);
      }
    };

    // Attach event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navRef]);

  return (
    <div className="fixed top-4 right-4 z-50" ref={navRef}>
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:outline-none"
        animate={{ rotate: isOpen ? 90 : 0 }} // Spin Animation for the hamburger menu into 'X'
        transition={{ duration: 0.5 }} // Spin effect duration
      >
        <div className="flex flex-col space-y-1">
          {/* First bar transforms into the top of the 'X' */}
          <span
            className={`block w-6 h-1 bg-teal-500 transition-transform duration-500 ${isOpen ? 'rotate-45 translate-y-2' : ''}`}
          ></span>
          {/* Middle bar disappears when clicked */}
          <span
            className={`block w-6 h-1 bg-teal-500 transition-opacity duration-500 ${isOpen ? 'opacity-0' : 'opacity-100'}`}
          ></span>
          {/* Third bar transforms into the bottom of the 'X' */}
          <span
            className={`block w-6 h-1 bg-teal-500 transition-transform duration-500 ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}
          ></span>
        </div>
      </motion.button>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="absolute right-0 mt-2 w-48 bg-black border border-gray-700 rounded-lg shadow-lg"
        >
          <Link to="/" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
            Home
          </Link>
          <Link to="/about" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
            About
          </Link>

          {/* Services Link with Dropdown */}
          <div>
            <Link to="/services" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
              Services
            </Link>
            <button
              onClick={() => setIsServicesOpen(!isServicesOpen)}
              className="block px-4 py-2 text-white hover:bg-teal-500 focus:outline-none w-full text-left"
            >
              {isServicesOpen ? '▴' : '▾'}
            </button>
          </div>

          {isServicesOpen && (
            <div className="pl-4">
              <Link to="/services/wood-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Wood Fence
              </Link>
              <Link to="/services/vinyl-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Vinyl Fence
              </Link>
              <Link to="/services/chain-link-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Chain Link Fence
              </Link>
              <Link to="/services/aluminum-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Aluminum Fence
              </Link>
              <Link to="/services/farm-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Farm Fence
              </Link>
              <Link to="/services/custom-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Custom Fence
              </Link>
              <Link to="/services/commercial-fence" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
                Commercial Fence
              </Link>
            </div>
          )}

          <Link to="/contact" className="block px-4 py-2 text-white hover:bg-teal-500" onClick={() => setIsOpen(false)}>
            Contact
          </Link>
        </motion.div>
      )}
    </div>
  );
};

export default NavBar;
