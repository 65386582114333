import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../Components/ContactBtn';
import logo from '/assets/logo.png';
import ServiceAreaMap from '../Components/ServiceAreaMap'; // Import the new map component
import Footer from '../Components/Footer';

const Home = () => {
  // Ensure page starts at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Hook for the "About Us" section
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Hooks for the "Our Services" boxes
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Hook for the "Testimonials" section
  const [testimonialRef, testimonialInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  // State management for form data
  const [formData, setFormData] = useState({
    first_name: "",
    home_phone: "",
    email: "",
    address_line1: "",
    service: "",
    message: "",
    customFields: {financing: false}
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "financing" ? undefined : type === "checkbox" ? checked : value,
      customFields: {
        ...prevData.customFields,
        financing: name === "financing" ? checked : prevData.customFields.financing
      }
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      console.log('Submitting form data:', formData);
  
      // Call serverless function hosted on Vercel
      const response = await fetch('/api/submitContact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      const result = await response.json();
      if (response.ok) {
        console.log('Contact created successfully:', result);
        setSubmissionStatus('Form submitted successfully!');
      } else {
        console.error('Error creating contact:', result);
        setSubmissionStatus('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSubmissionStatus('Error submitting form. Please try again.');
    }
  };

  return (
    <div>
      <ContactBtn />

      <div className="bg-black text-white">
        {/* Hero Section */}
        <section className="hero bg-cover bg-center h-screen flex items-center justify-center" style={{ backgroundImage: "url('/assets/HomePic.jpeg')" }}>
          <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dark Overlay for background */}
          <div className="relative z-20 text-center px-4">
            <motion.h1
              className="text-5xl font-bold"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              Welcome to Murphy Fencing
            </motion.h1>
            <motion.p
              className="mt-4 text-xl font-bold"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Your Trusted Partner for Quality Columbus Fencing
            </motion.p>
            <Link to="/about">
              <motion.button
                className="mt-6 px-6 py-3 bg-teal-500 hover:bg-teal-300 text-white font-semibold rounded-lg transition-all duration-300 ease-in-out"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                Learn More
              </motion.button>
            </Link>
          </div>
        </section>

        {/* Contact Form Section with Logo */}
        <section className="py-16 bg-black flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-4xl">
            <div className="mb-8 md:mb-0 md:mr-8 flex-shrink-0">
              <img src={logo} alt="Company Logo" className="max-w-xs h-auto" /> {/* Adjusted max width */}
            </div>
            <div className="w-full max-w-lg p-8 bg-gray-800 text-white rounded-lg shadow-lg flex-grow">
              <h2 className="text-3xl font-bold mb-6 text-center">Contact Us</h2>
              <form onSubmit={handleSubmit}>
                {/* Full Name Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="first_name">Full Name</label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="Your Full Name"
                    required
                  />
                </div>

                {/* Phone Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="home_phone">Phone Number</label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="tel"
                    id="home_phone"
                    name="home_phone"
                    value={formData.home_phone}
                    onChange={handleChange}
                    placeholder="Your Phone Number"
                    required
                  />
                </div>

                {/* Email Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="email">Email</label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                  />
                </div>

                {/* Address Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="address_line1">Address</label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    value={formData.address_line1}
                    onChange={handleChange}
                    placeholder="Your Address"
                    required
                  />
                </div>

                {/* Select A Service Dropdown */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="service">Select A Service</label>
                  <select
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose a Service</option>
                    <option value="privacy fence">Privacy Fence</option>
                    <option value="farm-fences">Farm Fences</option>
                    <option value="chain-link-fence">Chain Link Fence</option>
                    <option value="aluminum-fence">Aluminum Fence</option>
                    <option value="wood-fence">Wood Fence</option>
                    <option value="vinyl-fence">Vinyl Fence</option>
                    <option value="custom-fence">Custom Fence</option>
                    <option value="commercial-fence">Commercial Fence</option>
                  </select>
                </div>


              {/* Financing Checkbox */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="financing">
              Financing?
            </label>
            <input
              type="checkbox"
              id="financing"
              name="financing"
              checked={formData.customFields.financing}
              onChange={handleChange}
              className="form-checkbox text-orange-500"
            />
            <span className="ml-2">Yes</span>
          </div>



                {/* Message Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2" htmlFor="message">Message</label>
                  <textarea
                    className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="5"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button className="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-orange-500 transition-all duration-300 ease-in-out">
                    Send Message
                  </button>
                </div>
              </form>

              {/* Submission Status */}
              {submissionStatus && <p className="mt-4 text-center">{submissionStatus}</p>}
            </div>
          </div>
        </section>

        {/* About Section with Slower Fade-In Animation */}
        <motion.section
          ref={aboutRef}
          className="py-16"
          initial={{ opacity: 0 }}
          animate={aboutInView ? { opacity: 1 } : {}}
          transition={{ duration: 3 }} // Slower fade-in
        >
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-white">Need A New Fence?</h2>
            <p className="mt-4 text-gray-300">
              We are a leading fencing company with over 20 years of experience in providing top-notch fencing solutions.
              Our mission is to deliver exceptional service and superior craftsmanship to every project.
            </p>
          </div>
        </motion.section>

        {/* Services Section with Fade-In and Upward Motion */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-white mb-8">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div
                ref={ref1}
                className="service-card p-6 bg-teal-500 text-white shadow-lg rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={inView1 ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
              >
                <h3 className="text-xl font-semibold">Residential Fencing</h3>
                <p className="mt-2">
                  Protect your home with our durable and stylish residential fencing options.
                </p>
              </motion.div>

              <motion.div
                ref={ref2}
                className="service-card p-6 bg-teal-500 text-white shadow-lg rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={inView2 ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
              >
                <h3 className="text-xl font-semibold">Commercial Fencing</h3>
                <p className="mt-2">
                  Secure your business premises with our robust and reliable commercial fencing solutions.
                </p>
              </motion.div>

              <motion.div
                ref={ref3}
                className="service-card p-6 bg-teal-500 text-white shadow-lg rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={inView3 ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
              >
                <h3 className="text-xl font-semibold">Custom Fencing</h3>
                <p className="mt-2">
                  Get fencing that fits your unique needs with our custom design services.
                </p>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Interactive Map Section */}
        <section className="py-16 bg-black relative z-10">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-white mb-8">Areas We Service</h2>
            <div className="w-full h-80">
              <ServiceAreaMap /> {/* Use the new ServiceAreaMap component */}
            </div>
          </div>
        </section>

        {/* Testimonials Section with Scroll Animation */}
        <section className="py-80">  {/* Make sure the padding is enough */}
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-white">What Our Clients Say</h2>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                ref={testimonialRef}
                className="testimonial bg-teal-500 p-6 text-white shadow-lg rounded-lg"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={testimonialInView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.5 }}
              >
                <p>"We needed a fence installed at our new home and had a few quotes done to see fair pricing. Nathan came out and gave us a very fair price and was very honest. 
                  He took time to find the plot pins, which none of the other estimators did, and ended up getting us more yard than we thought we had."</p>
                <p className="mt-4 font-semibold">- Taylor Neville</p>
              </motion.div>

              <motion.div
                ref={testimonialRef}
                className="testimonial bg-teal-500 p-6 text-white shadow-lg rounded-lg"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={testimonialInView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <p>"Murphy Fence & team get 5 stars from me and my husband! From start to finish Nathan & Connor answered all the questions and concerns we had. 
                  TJ & Brian did an excellent job, they worked HARD and went above and beyond to produce/install a beautiful fence for my family!!! We would highly recommend this company to anyone looking for a new privacy fence. Thank you all!"</p>
                <p className="mt-4 font-semibold">- Aimee High</p>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section className="py-16 bg-black text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold">Ready to Get Started?</h2>
            <p className="mt-4 text-xl">
              Contact us today for a free consultation and quote!
            </p>
            <Link to="/contact">
              <button className="mt-6 px-6 py-3 bg-teal-500 text-white font-semibold rounded-lg hover:bg-teal-300 transition-all duration-300 ease-in-out">
                Contact Us
              </button>
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Home;