import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/VinylFencePics/VInylFenceHero.jpg',
  '/assets/VinylFencePics/VinylFenceWork1.jpg',
  '/assets/VinylFencePics/VinylFenceWork2.jpg',
  '/assets/VinylFencePics/VinylFenceWork3.jpg',
  '/assets/VinylFencePics/VinylFenceWork4.jpg',
  '/assets/VinylFencePics/VinylFenceWork5.jpg',
  '/assets/VinylFencePics/VinylFenceWork6.jpg',
];

const VinylFence = () => {

// Scroll to the top on component mount
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const { ref: whyVinylRef, inView: whyVinylInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

       {/* Hero Section */}
       <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${images[0]})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Vinyl Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Explore the durability and low maintenance of vinyl fences. A perfect blend of style and resilience for your property.
          </motion.p>
        </div>
      </section>

      {/* Why Vinyl? Section */}
      <section ref={whyVinylRef} className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            initial={{ opacity: 0, x: -100 }}
            animate={whyVinylInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyVinylInView ? 0.5 : 0 }}
          >
            Why Vinyl?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, x: 100 }}
            animate={whyVinylInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyVinylInView ? 0.8 : 0 }}
          >
            Vinyl fences are known for their strength, flexibility, and long-lasting durability. They require minimal maintenance and can withstand harsh weather conditions, making them an excellent choice for homeowners.
          </motion.p>
          <motion.ul
            className="list-disc list-inside text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyVinylInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyVinylInView ? 1.1 : 0 }}
          >
            <li>Resistant to rot, decay, and insect damage.</li>
            <li>Easy to clean with just soap and water.</li>
            <li>Available in a variety of styles and colors.</li>
            <li>Eco-friendly, recyclable, and cost-effective.</li>
          </motion.ul>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            animate={whyVinylInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyVinylInView ? 1.3 : 0 }}
          >
            Vinyl fences provide an attractive and practical solution for your fencing needs, offering both security and aesthetic appeal.
          </motion.p>
        </div>
      </section>

      {/* Why Us? Section */}
      <section ref={whyUsRef} className="py-16 bg-gray-800 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            initial={{ opacity: 0, x: -100 }}
            animate={whyUsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 0.5 : 0 }}
          >
            Why Choose Murphy Fencing?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, x: 100 }}
            animate={whyUsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 0.8 : 0 }}
          >
            At Murphy Fencing, we are dedicated to delivering high-quality fencing solutions that meet your needs and exceed your expectations. Our vinyl fences are installed with precision and care, ensuring durability and beauty.
          </motion.p>
          <motion.ul
            className="list-disc list-inside text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 1.1 : 0 }}
          >
            <li>Expert installation with attention to detail.</li>
            <li>High-quality vinyl materials that last for years.</li>
            <li>Customized solutions tailored to your property's needs.</li>
            <li>Commitment to customer satisfaction and service.</li>
          </motion.ul>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 1.3 : 0 }}
          >
            Trust Murphy Fencing to provide the best vinyl fencing solutions for your home. Contact us today to get started!
          </motion.p>
        </div>
      </section>

      {/* Our Work Section */}
      <section className="py-16 bg-black text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-12"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            Our Work
          </motion.h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {images.slice(1).map((image, index) => (
              <motion.div
                key={index}
                className="w-full h-64 overflow-hidden rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: index * 0.2 }}
              >
                <img src={image} alt={`Work ${index + 1}`} className="w-full h-full object-cover" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default VinylFence;
