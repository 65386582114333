import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Components/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: "",    
    home_phone: "",  
    email: "",
    address_line1: "",  
    service: "",
    message: "",
    customFields: { financing: false } // Initialize financing as a boolean within customFields
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? undefined : value, // Use value unless it's a checkbox
      customFields: {
        ...prevData.customFields,
        financing: name === "financing" ? checked : prevData.customFields.financing // Update financing correctly
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('Submitting form data:', formData);

      // Call serverless function or endpoint
      const response = await fetch('/api/submitContact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Contact created successfully:', result);
        setSubmissionStatus('Form submitted successfully!');
      } else {
        console.error('Error creating contact:', result);
        setSubmissionStatus('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSubmissionStatus('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-8">
      <div className="w-full max-w-lg p-8 bg-gray-800 text-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">Contact Us</h2>

        <form onSubmit={handleSubmit}>
          {/* Full Name Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="first_name">Full Name</label>
            <input
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder="Your Full Name"
              required
            />
          </div>

          {/* Phone Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="home_phone">Phone Number</label>
            <input
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              type="tel"
              id="home_phone"
              name="home_phone"
              value={formData.home_phone}
              onChange={handleChange}
              placeholder="Your Phone Number"
              required
            />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="email">Email</label>
            <input
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
          </div>

          {/* Address Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="address_line1">Address</label>
            <input
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              type="text"
              id="address_line1"
              name="address_line1"
              value={formData.address_line1}
              onChange={handleChange}
              placeholder="Your Address"
              required
            />
          </div>

          {/* Select A Service Dropdown */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="service">Select A Service</label>
            <select
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Choose a Service</option>
              <option value="privacy fence">Privacy Fence</option>
              <option value="farm-fences">Farm Fences</option>
              <option value="chain-link-fence">Chain Link Fence</option>
              <option value="aluminum-fence">Aluminum Fence</option>
              <option value="wood-fence">Wood Fence</option>
              <option value="vinyl-fence">Vinyl Fence</option>
              <option value="custom-fence">Custom Fence</option>
              <option value="commercial-fence">Commercial Fence</option>
            </select>
          </div>

          {/* Financing Checkbox */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="financing">
              Financing?
            </label>
            <input
              type="checkbox"
              id="financing"
              name="financing"
              checked={formData.customFields.financing}
              onChange={handleChange}
              className="form-checkbox text-orange-500"
            />
            <span className="ml-2">Yes</span>
          </div>

          {/* Message Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="message">Message</label>
            <textarea
              className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              placeholder="Your Message"
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-orange-500 transition-all duration-300 ease-in-out"
            >
              Send Message
            </button>
          </div>
        </form>

        {/* Submission Status */}
        {submissionStatus && <p className="mt-4 text-center">{submissionStatus}</p>}
      </div>

       {/* Contact Information Boxes */}
       <div className="mt-8 w-full max-w-5xl flex flex-col md:flex-row justify-between gap-4">
        {/* Phone Number Box */}
        <div className="flex-1 p-6 bg-gray-800 text-center rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-2">Phone</h3>
          <p className="text-lg">614-261-2160</p>
          <FontAwesomeIcon icon={faPhone} size="2x" className="mb-2 text-teal-500" />
        </div>

        {/* Email Address Box */}
        <div className="flex-1 p-6 bg-gray-800 text-center rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-2">Email</h3>
          <p className="text-lg">Contact.columbusfence@gmail.com</p>
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="mb-2 text-teal-500" />
        </div>

        {/* Physical Address Box */}
        <div className="flex-1 p-6 bg-gray-800 text-center rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-2">Address</h3>
          <p className="text-lg">1509 Blatt Blvd. Gahanna, OH 43230</p>
          <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="mb-2 text-teal-500" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
