import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/FarmFencePics/FarmFenceWork1.jpg',
  '/assets/FarmFencePics/FarmFenceWork2.jpg',
  '/assets/FarmFencePics/FarmFenceWork3.jpg',
  '/assets/FarmFencePics/FarmFenceWork4.jpg',
  '/assets/FarmFencePics/FarmFenceWork5.jpg',
  '/assets/FarmFencePics/FarmFenceWork6.jpg',
];

const FarmFence = () => {

// Scroll to the top on component mount
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const { ref: whyFarmFenceRef, inView: whyFarmFenceInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url('/assets/FarmFencePics/FarmFenceHero.jpg')`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Farm Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Ensure the safety and security of your farm with durable and reliable fencing solutions.
          </motion.p>
        </div>
      </section>

      {/* Why Farm Fence Section */}
      <section
        className="py-16 bg-gray-900 text-white"
        ref={whyFarmFenceRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyFarmFenceInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Farm Fences?</h2>
          <p className="text-lg leading-relaxed mb-6">
            Farm fences are essential for keeping your livestock secure and protecting your crops. They are designed to withstand the demands of farm life, offering both strength and longevity.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Protects livestock from wandering off</li>
            <li>Durable materials designed for long-lasting performance</li>
            <li>Customizable to meet your specific farm's needs</li>
            <li>Ensures the safety of your animals and crops</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Whether you have a large farm or a smaller homestead, our farm fencing solutions are tailored to provide the security and functionality you need.
          </p>
        </motion.div>
      </section>

      {/* Why Us Section */}
      <section
        className="py-16 bg-gray-800 text-white"
        ref={whyUsRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Choose Murphy Fencing?</h2>
          <p className="text-lg leading-relaxed mb-6">
            At Murphy Fencing, we understand the unique needs of farms. Our farm fences are designed to meet the demands of agricultural life while providing both durability and aesthetic appeal. We ensure that every fence we install is made with care, offering you peace of mind for years to come.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Expert installation tailored to your farm's layout</li>
            <li>High-quality materials built for tough environments</li>
            <li>Custom fencing solutions for your livestock and property</li>
            <li>Commitment to customer satisfaction and safety</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Reach out today to learn how we can help secure your farm with our reliable fencing solutions.
          </p>
        </motion.div>
      </section>

      {/* Image Gallery Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Our Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {images.map((image, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={image}
                  alt={`Farm Fence Work ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default FarmFence;
