import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import FarmFence from './pages/Services/FarmFence'; // Import FarmFence page
import ChainLinkFence from './pages/Services/ChainLinkFence'; // Import ChainLinkFence page
import AluminumFence from './pages/Services/AluminumFence'; // Import AluminumFence page
import WoodFence from './pages/Services/WoodFence'; // Import WoodFence page
import VinylFence from './pages/Services/VinylFence'; // Import VinylFence page
import CustomFence from './pages/Services/CustomFence'; 
import CommercialFence from './pages/Services/CommercialFence';
function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/farm-fence" element={<FarmFence />} /> {/* FarmFence Route */}
        <Route path="/services/chain-link-fence" element={<ChainLinkFence />} /> {/* ChainLinkFence Route */}
        <Route path="/services/aluminum-fence" element={<AluminumFence />} /> {/* AluminumFence Route */}
        <Route path="/services/wood-fence" element={<WoodFence />} /> {/* WoodFence Route */}
        <Route path="/services/vinyl-fence" element={<VinylFence />} /> {/* VinylFence Route */}
        <Route path="/services/custom-fence" element={<CustomFence />} /> { /* CustomFence Route */ }
        <Route path="/services/commercial-fence" element={<CommercialFence />} /> { /* CommercialFence Route */ }
      </Routes>
    </Router>
  );
}

export default App;
