import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../Components/Footer';
import ContactBtn from '../Components/ContactBtn';

const services = [
  {
    name: 'Farm Fence',
    image: '/assets/ServicesPics/FarmFence.jpg',
    link: '/services/farm-fence',
  },
  {
    name: 'Chain Link Fence',
    image: '/assets/ServicesPics/ChainLink.jpg',
    link: '/services/chain-link-fence',
  },
  {
    name: 'Aluminum Fence',
    image: '/assets/ServicesPics/Aluminum.jpeg',
    link: '/services/aluminum-fence',
  },
  {
    name: 'Wood Fence',
    image: '/assets/ServicesPics/WoodFence.jpg',
    link: '/services/wood-fence',
  },
  {
    name: 'Vinyl Fence',
    image: '/assets/ServicesPics/VinylFence.jpg',
    link: '/services/vinyl-fence',
  },
  {
    name: 'Custom Fence',
    image: '/assets/ServicesPics/CustomFence.jpg',
    link: '/services/custom-fence',
  },
  {
    name: 'Commercial Fence',
    image: '/assets/ServicesPics/CommercialFence.jpg',
    link: '/services/commercial-fence',
  },
];

const Services = () => {
  // Scroll to the top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black text-white min-h-screen p-8">
      <ContactBtn />
      <div className="container mx-auto">
        <h1 className="text-5xl font-bold text-center mb-12">Our Services</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="relative overflow-hidden rounded-lg shadow-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={service.image}
                alt={service.name}
                className="w-full h-64 object-cover"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                <div className="text-center">
                  <h2 className="text-2xl font-bold text-white mb-4">{service.name}</h2>
                  <Link
                    to={service.link}
                    className="px-6 py-2 bg-red-600 text-white font-semibold rounded-lg hover:bg-orange-500 transition-all duration-300 ease-in-out"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;