import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '/assets/logo.png'; // Update this path if necessary

const Footer = () => {
  return (
    <footer className="bg-gradient-to-t from-black via-gray-900 to-gray-800 text-white py-8 mt-16">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start">
        {/* Left Side - Services and Areas We Serve */}
        <div className="md:w-1/2">
          <h3 className="text-2xl font-bold mb-4">Services We Offer</h3>
          <ul className="list-none mb-8">
            <li><Link to="/services/wood-fence" className="hover:underline">Wood Fencing</Link></li>
            <li><Link to="/services/vinyl-fence" className="hover:underline">Vinyl Fencing</Link></li>
            <li><Link to="/services/aluminum-fence" className="hover:underline">Aluminum Fencing</Link></li>
            <li><Link to="/services/chain-link-fence" className="hover:underline">Chain Link Fencing</Link></li>
            <li><Link to="/services/farm-fence" className="hover:underline">Farm Fencing</Link></li>
            <li><Link to="/services/custom-fence" className="hover:underline">Custom Fencing</Link></li>
            <li><Link to="/services/commercial-fence" className="hover:underline">Commercial Fencing</Link></li>
          </ul>
          <h3 className="text-2xl font-bold mb-4">Where We Service</h3>
          <ul className="list-none">
            <li>Licking County, OH</li>
            <li>Franklin County, OH</li>
            <li>Delaware County, OH</li>
          </ul>
        </div>

        {/* Right Side - Company Info */}
        <div className="md:w-1/2 flex flex-col items-end">
          <img src={logo} alt="Company Logo" className="w-32 h-auto mb-4" />
          <p className="mb-8 text-right">
            Murphy Fence LLC is dedicated to providing high-quality fencing solutions with exceptional customer service. We take pride in our work and strive to meet the unique needs of each client.
          </p>
          <div className="flex flex-col items-end space-y-4">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPhone} className="text-teal-500 mr-2" />
              <span>(614) 261-2160</span>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="text-teal-500 mr-2" />
              <span>contact.columbusfence@gmail.com</span>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faClock} className="text-teal-500 mr-2" />
              <span>Mon-Fri: 7am - 7pm</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
