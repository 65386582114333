import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/CustomFencePics/CustomFence1.jpg',
  '/assets/CustomFencePics/CustomFence2.jpg',
  '/assets/CustomFencePics/CustomFence3.jpg',
  '/assets/CustomFencePics/CustomFence4.jpg',
  '/assets/CustomFencePics/CustomFence5.jpg',
  '/assets/CustomFencePics/CustomFence6.jpg',
];

const CustomFence = () => {
  const { ref: whyCustomRef, inView: whyCustomInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Ensuring the page renders from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url('/assets/CustomFencePics/CustomFenceHero.jpg')`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Custom Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Tailor-made fencing solutions designed to meet your unique needs and style preferences.
          </motion.p>
        </div>
      </section>

      {/* Why Custom Fence Section */}
      <section
        className="py-16 bg-gray-900 text-white"
        ref={whyCustomRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyCustomInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Custom Fences?</h2>
          <p className="text-lg leading-relaxed mb-6">
            Custom fences offer limitless design possibilities. Whether you want to enhance privacy, security, or aesthetics, custom fences allow you to create a fence that perfectly fits your property’s unique specifications and your personal style.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Designed to suit your individual taste and property layout</li>
            <li>Available in a variety of materials, including wood, vinyl, and metal</li>
            <li>Enhances the value and curb appeal of your home or business</li>
            <li>Provides both functionality and style for any project</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Whether you're seeking modern elegance or a classic look, custom fencing offers the versatility and precision needed to bring your vision to life.
          </p>
        </motion.div>
      </section>

      {/* Why Us Section */}
      <section
        className="py-16 bg-gray-800 text-white"
        ref={whyUsRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Choose Murphy Fencing?</h2>
          <p className="text-lg leading-relaxed mb-6">
            At Murphy Fencing, we specialize in creating custom fence solutions that meet your needs and exceed your expectations. Our team of skilled professionals works closely with you to design and install a fence that complements your property while delivering unmatched quality and durability.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Expert craftsmanship and attention to detail</li>
            <li>Custom designs tailored to your preferences</li>
            <li>Durable materials that ensure long-lasting results</li>
            <li>Commitment to customer satisfaction</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Ready to build the perfect custom fence? Contact us today to get started with a consultation and free quote.
          </p>
        </motion.div>
      </section>

      {/* Image Gallery Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Our Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {images.map((image, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={image}
                  alt={`Custom Fence Work ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CustomFence;
