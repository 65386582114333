import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/AluminumFencePics/AluminumHero.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork1.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork2.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork3.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork4.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork5.jpg',
  '/assets/AluminumFencePics/AluminumFenceWork6.jpg',
];

const AluminumFence = () => {
  const { ref: whyAluminumRef, inView: whyAluminumInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

       {/* Hero Section */}
       <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${images[0]})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Aluminum Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            A blend of elegance and strength, perfect for residential and commercial properties.
          </motion.p>
        </div>
      </section>

      {/* Why Aluminum Fence Section */}
      <section
        className="py-16 bg-gray-900 text-white"
        ref={whyAluminumRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyAluminumInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Aluminum Fences?</h2>
          <p className="text-lg leading-relaxed mb-6">
            Aluminum fences provide a combination of aesthetic appeal and security, making them ideal for both homes and businesses. They are durable, low-maintenance, and can be customized to fit any property.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Corrosion-resistant, perfect for all weather conditions</li>
            <li>Low-maintenance with long-lasting durability</li>
            <li>Customizable designs to match any aesthetic</li>
            <li>Provides security while maintaining a sleek, elegant look</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Whether you're looking to enhance your home's curb appeal or secure your commercial property, aluminum fences offer the perfect solution.
          </p>
        </motion.div>
      </section>

      {/* Why Us Section */}
      <section
        className="py-16 bg-gray-800 text-white"
        ref={whyUsRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Choose Murphy Fencing?</h2>
          <p className="text-lg leading-relaxed mb-6">
            At Murphy Fencing, we specialize in installing high-quality aluminum fences that provide both beauty and functionality. Our team of professionals ensures that every fence is installed with precision and care, ensuring durability and customer satisfaction.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Expert installation with attention to detail</li>
            <li>Custom solutions tailored to your specific needs</li>
            <li>High-quality, long-lasting materials</li>
            <li>Excellent customer service and satisfaction guarantee</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Contact us today to learn more about how we can help you enhance your property with an aluminum fence.
          </p>
        </motion.div>
      </section>

      {/* Image Gallery Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Our Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {images.slice(1).map((image, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={image}
                  alt={`Aluminum Fence Work ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AluminumFence;
