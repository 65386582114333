import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/WoodFencePics/WoodFenceHero.jpg',
  '/assets/WoodFencePics/WoodFenceWork1.jpg',
  '/assets/WoodFencePics/WoodFenceWork2.jpg',
  '/assets/WoodFencePics/WoodFenceWork3.jpg',
  '/assets/WoodFencePics/WoodFenceWork4.jpg',
  '/assets/WoodFencePics/WoodFenceWork5.jpg',
  '/assets/WoodFencePics/WoodFenceWork6.jpg',
];

const WoodFence = () => {

// Ensure page starts at the top
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const { ref: whyWoodRef, inView: whyWoodInView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when the section is about halfway on the screen
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when the section is about halfway on the screen
  });

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${images[0]})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Wooden Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Discover the elegance and durability of wooden fences. Perfect for privacy, security, and adding a classic touch to your property.
          </motion.p>
        </div>
      </section>

      {/* Why Wood? Section */}
      <section ref={whyWoodRef} className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            initial={{ opacity: 0, x: -100 }}
            animate={whyWoodInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyWoodInView ? 0.5 : 0 }}
          >
            Why Wood?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, x: 100 }}
            animate={whyWoodInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyWoodInView ? 0.8 : 0 }}
          >
            Wooden fences offer a timeless and natural aesthetic that blends seamlessly with the environment. They are versatile, customizable, and can be designed to fit any style, from rustic to modern.
          </motion.p>
          <motion.ul
            className="list-disc list-inside text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyWoodInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyWoodInView ? 1.1 : 0 }}
          >
            <li>Natural beauty that enhances the look of any property.</li>
            <li>Environmentally friendly, sourced from sustainable forests.</li>
            <li>Customizable designs to suit your taste and needs.</li>
            <li>Durable and long-lasting with proper maintenance.</li>
          </motion.ul>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            animate={whyWoodInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyWoodInView ? 1.3 : 0 }}
          >
            A wooden fence adds value and privacy to your property, making it a smart investment for homeowners who appreciate quality and craftsmanship.
          </motion.p>
        </div>
      </section>

      {/* Why Us? Section */}
      <section ref={whyUsRef} className="py-16 bg-gray-800 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            initial={{ opacity: 0, x: -100 }}
            animate={whyUsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 0.5 : 0 }}
          >
            Why Choose Murphy Fencing?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, x: 100 }}
            animate={whyUsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 0.8 : 0 }}
          >
            At Murphy Fencing, we pride ourselves on our dedication to quality and customer satisfaction. Our experienced team works closely with you to design and install a fence that meets your specific needs and exceeds your expectations.
          </motion.p>
          <motion.ul
            className="list-disc list-inside text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 1.1 : 0 }}
          >
            <li>Expert craftsmanship with decades of experience.</li>
            <li>High-quality materials that ensure durability and longevity.</li>
            <li>Personalized service tailored to your unique requirements.</li>
            <li>Comprehensive warranties and support after installation.</li>
          </motion.ul>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.5, delay: whyUsInView ? 1.3 : 0 }}
          >
            When you choose Murphy Fencing, you're choosing a company that values integrity, excellence, and customer satisfaction. Let us help you create the perfect wooden fence for your property.
          </motion.p>
        </div>
      </section>

      {/* Our Work Section */}
      <section className="py-16 bg-black text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-12"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            Our Work
          </motion.h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {images.slice(1).map((image, index) => (
              <motion.div
                key={index}
                className="w-full h-64 overflow-hidden rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: index * 0.2 }}
              >
                <img src={image} alt={`Work ${index + 1}`} className="w-full h-full object-cover" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default WoodFence;
