import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/ChainLinkPics/ChainLinkWork1.jpg',
  '/assets/ChainLinkPics/ChainLinkWork2.jpg',
  '/assets/ChainLinkPics/ChainLinkWork3.jpg',
  '/assets/ChainLinkPics/ChainLinkWork4.jpg',
  '/assets/ChainLinkPics/ChainLinkWork5.jpg',
  '/assets/ChainLinkPics/ChainLinkWork6.jpg',
];

const ChainLinkFence = () => {

// Scroll to the top on component mount
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const { ref: whyChainLinkRef, inView: whyChainLinkInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url('/assets/ChainLinkPics/ChainLinkHero.jpg')`, // Updated for Chain Link
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Chain Link Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Durable, affordable, and perfect for both residential and commercial properties. Chain link fences are a reliable solution for security.
          </motion.p>
        </div>
      </section>

      {/* Why Chain Link Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            ref={whyChainLinkRef}
            initial={{ opacity: 0, y: 50 }}
            animate={whyChainLinkInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
          >
            Why Choose Chain Link Fencing?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyChainLinkInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Chain link fences offer unbeatable durability and security. They are ideal for residential backyards, commercial properties, and industrial zones. Made from corrosion-resistant materials, they require minimal maintenance while standing strong in all weather conditions.
          </motion.p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyChainLinkInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.7 }}
            >
              Cost-effective and durable fencing solution.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyChainLinkInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.9 }}
            >
              Provides security while maintaining visibility.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyChainLinkInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 1.1 }}
            >
              Easy installation and minimal maintenance.
            </motion.li>
          </ul>
        </div>
      </section>

      {/* Why Us Section */}
      <section className="py-16 bg-gray-800 text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold mb-6"
            ref={whyUsRef}
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
          >
            Why Choose Murphy Fencing?
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1, delay: 0.5 }}
          >
            At Murphy Fencing, we specialize in providing top-quality chain link fences that are built to last. Our team of professionals ensures quick and hassle-free installation, with an emphasis on quality and customer satisfaction.
          </motion.p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.7 }}
            >
              Experienced and skilled team of installers with decades of experience.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.9 }}
            >
              High-quality materials and customer-focused service.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 50 }}
              animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 1.1 }}
            >
              Comprehensive warranties for peace of mind.
            </motion.li>
          </ul>
        </div>
      </section>

      {/* Our Work Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6 text-center">Our Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {images.map((src, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg"
                whileHover={{ scale: 1.05 }}
              >
                <img src={src} alt={`Chain Link Fence Work ${index + 1}`} className="w-full h-full object-cover" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ChainLinkFence;
