import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ContactBtn from '../../Components/ContactBtn';
import Footer from '../../Components/Footer';

const images = [
  '/assets/CommercialFencePics/CommercialFence1.jpg',
  '/assets/CommercialFencePics/CommercialFence2.jpg',
  '/assets/CommercialFencePics/CommercialFence3.jpg',
  '/assets/CommercialFencePics/CommercialFence4.jpg',
  '/assets/CommercialFencePics/CommercialFence5.jpg',
  '/assets/CommercialFencePics/CommercialFence6.jpg',
];

const CommercialFence = () => {
  const { ref: whyCommercialRef, inView: whyCommercialInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: whyUsRef, inView: whyUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Ensure the page renders from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black text-white min-h-screen">
      <ContactBtn />

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-screen flex items-center justify-center relative"
        style={{
          backgroundImage: `url('/assets/CommercialFencePics/CommercialFenceHero.jpeg')`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative text-center px-4">
          <motion.h1
            className="text-5xl font-bold text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Commercial Fences
          </motion.h1>
          <motion.p
            className="mt-4 text-xl max-w-xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
          >
            Providing security, durability, and style for all your commercial fencing needs.
          </motion.p>
        </div>
      </section>

      {/* Why Commercial Fence Section */}
      <section
        className="py-16 bg-gray-900 text-white"
        ref={whyCommercialRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyCommercialInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Commercial Fences?</h2>
          <p className="text-lg leading-relaxed mb-6">
            Commercial fences offer the perfect blend of security and style. Whether you're looking to protect your business premises or enhance its curb appeal, commercial fences provide a practical, durable, and attractive solution.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Durable materials designed for long-lasting performance</li>
            <li>High-security solutions for commercial and industrial properties</li>
            <li>Custom designs that align with your business's aesthetic</li>
            <li>Low maintenance and cost-effective solutions</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Secure your property with a commercial fence that fits your business needs, without sacrificing style or durability.
          </p>
        </motion.div>
      </section>

      {/* Why Us Section */}
      <section
        className="py-16 bg-gray-800 text-white"
        ref={whyUsRef}
      >
        <motion.div
          className="container mx-auto px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={whyUsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
        >
          <h2 className="text-3xl font-bold mb-6">Why Choose Murphy Fencing?</h2>
          <p className="text-lg leading-relaxed mb-6">
            At Murphy Fencing, we understand the importance of securing your commercial property while maintaining a professional appearance. Our expert team provides tailored solutions, using only the highest-quality materials to ensure both security and aesthetic appeal.
          </p>
          <ul className="list-disc list-inside text-lg leading-relaxed mb-6">
            <li>Expert installation with a focus on security</li>
            <li>Customized designs for your business</li>
            <li>Durable materials that withstand harsh conditions</li>
            <li>Comprehensive warranties for your peace of mind</li>
          </ul>
          <p className="text-lg leading-relaxed">
            Trust Murphy Fencing to deliver the best commercial fencing solution for your business. Contact us today for a consultation and quote.
          </p>
        </motion.div>
      </section>

      {/* Image Gallery Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Our Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {images.map((image, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={image}
                  alt={`Commercial Fence Work ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CommercialFence;
